// Lightest possible code
document.addEventListener("DOMContentLoaded", function (event) {

  // setting height in sticky bar for positionn sticky
  var tripura_height = window.getComputedStyle(
    document.querySelector(".tripura_cont")
  ).height;
  document.querySelector(".social_height").style.height = tripura_height;
});

function mob_menu_function(event) {
  document.querySelector(".menucont").classList.add("show_menu");
}

function close_nav(event) {
  document.querySelector(".menucont").classList.remove("show_menu");
}

window.addEventListener("scroll", function () {
  var nav = document.querySelector(".nav_outer_for_nav");
  if (nav.offsetTop < window.pageYOffset) {
    document.querySelector(".nav_outer_for_nav").classList.add("fixed");
  } else {
    document.querySelector(".nav_outer_for_nav").classList.remove("fixed");
  }
});
